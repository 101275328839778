@import "antd/lib/style/themes/default.less";
@import "antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "theme.less"; // variables to override above

#root,
body,
html {
  height: 100vh;
}

small {
  font-size: @font-size-small;
}
strong,
b {
  font-weight: @fontWeightBold;
}

// App
.app {
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  color: @viola;
}

// Content
.content {
  padding: 20px;
}

// Header
.header {
  min-height: 80px;
  .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  h1 {
    font-weight: @fontWeightBold;
  }
}

// Proposta
.proposta {
  .ant-form-item-control-input-content {
    font-weight: @fontWeightBold;
  }
  .ant-form-item-label > label {
    color: @grey;
  }
}

// Footer
.footer {
  text-align: left;
  padding: 20px;
  background: @layout-footer-background;
  color: @grey;
  font-size: @font-size-micro;
  .info {
    font-size: @font-size-nano;
    line-height: 15px;
  }
}

// ******************************  UTILITY CLASS ***************************
// Padding
.p0 {
  padding: 0;
}

.p1 {
  padding: 1em; // o 15px o 16px 1rem
}

.p2 {
  padding: 2em;
}

.pl0 {
  padding-left: 0;
}

.pl1 {
  padding-left: 1em; // o 15px o 16px 1rem
}
.pl1_5 {
  padding-left: 1.5em; // o 15px o 16px 1rem
}

.pl2 {
  padding-left: 2em;
}

.pr0 {
  padding-left: 0;
}

.pr1 {
  padding-left: 1em; // o 15px o 16px 1rem
}

.pr2 {
  padding-left: 2em;
}

// Margin
.mb0 {
  margin-bottom: 0;
}

.mb05 {
  margin-bottom: 0.5em;
}

.mb1 {
  margin-bottom: 1em;
}

.mb2 {
  margin-bottom: 2em;
}

.mb3 {
  margin-bottom: 3em;
}

.mb4 {
  margin-bottom: 4em;
}

.mb5 {
  margin-bottom: 5em;
}

.mt0 {
  margin-top: 0;
}

.mt05 {
  margin-top: 0.5em;
}

.mt1 {
  margin-top: 1em;
}

.mt2 {
  margin-top: 2em;
}

.mt4 {
  margin-top: 4em;
}

.mt5 {
  margin-top: 5em;
}

.my0 {
  .mt0;
  .mb0;
}

.my05 {
  .mt05;
  .mb05;
}

.my1 {
  .mt1;
  .mb1;
}

.my2 {
  .mt2;
  .mb2;
}

.ml0 {
  margin-left: 0;
}
.ml1 {
  margin-left: 1em;
}
.ml2 {
  margin-left: 1em;
}
.ml3 {
  margin-left: 3em;
}
.ml4 {
  margin-left: 4em;
}
.ml5 {
  margin-left: 5em;
}

.mr0 {
  margin-left: 0;
}
.mr1 {
  margin-left: 1em;
}
.mr2 {
  margin-left: 1em;
}
.mr3 {
  margin-left: 3em;
}
.mr4 {
  margin-left: 4em;
}
.mr5 {
  margin-left: 5em;
}

.mx0 {
  .ml0;
  .mr0;
}

.mx1 {
  .ml1;
  .mr1;
}

.mx2 {
  .ml2;
  .mr2;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.uppercase {
  text-transform: uppercase;
  input {
    text-transform: uppercase;
  }
}

.text-color {
  &--orange {
    color: @orange;
  }
  &--grey {
    color: @grey;
  }
}

.text-size {
  &--large {
    font-size: @font-size-large;
    line-height: 27px;
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.fontWeightRegular {
  font-weight: @fontWeightRegular !important;
}

.fontWeightBold {
  font-weight: @fontWeightBold !important;
}

.vertical-aling-middle {
  vertical-align: middle;
}

.centerBlock {
  display: block;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

.rounded {
  border-radius: 40px;
}

// Spin
.ant-spin {
  .ant-spin-dot-item {
    background: @orange;
  }
}

// Input
.ant-input,
.ant-picker-input > input {
  line-height: 35px;
}

.ant-picker-input > input {
  padding: 3px 0;
}

// BTN
.ant-btn {
  line-height: 18px;
  font-weight: @fontWeightBold;
  letter-spacing: 0.48;
}

.ant-btn-text {
  padding: 0;
  color: @grey;
  text-transform: uppercase;
  font-size: @font-size-small;
  font-weight: @fontWeightRegular;
}

.ant-btn-default {
  border-color: @orange;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 48px;
  &:hover,
  &:active,
  &:focus {
    color: @white;
    border-color: @orangeDark;
    background: @orangeDark;

    .anticon {
      color: @orange;
    }
  }

  &.squared {
    border-radius: 3px;
  }

  &.noborder {
    border-width: 0;
  }
}

// Switch

.ant-switch {
  min-width: 54px;
  height: 32px;
  .ant-switch-handle {
    height: 28px;
    width: 28px;
    &::before {
      border-radius: 100%;
    }
  }
  &.ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 30px);
    }
  }
}

// Disable Required
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}

// Alter boolean field
.boolean-field {
  &:not(.readonly) {
    .ant-form-item-row {
      flex-flow: nowrap;
      flex-direction: row-reverse;
      .ant-form-item-label,
      .ant-form-item-control {
        max-width: none;
        flex: auto;
      }
      .ant-form-item-control {
        width: auto;
        padding-right: 20px;
      }
      .ant-switch-inner {
        margin-left: 32px;
        margin-right: 7px;
      }
      .ant-switch-checked .ant-switch-inner {
        margin-right: 32px;
        margin-left: 7px;
      }
      .ant-form-item-label {
        display: flex;
        align-items: flex-end;
        .customLabel {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          .label {
            width: 100%;
          }
          .error {
            width: 100%;
            color: @error-color;
            visibility: hidden;
            height: 0;
          }
        }
      }
    }
    &.ant-form-item-has-error {
      .ant-form-item-row {
        .ant-form-item-label {
          .error {
            visibility: visible;
            height: 24px;
          }
        }
      }
    }
    .ant-form-item-margin-offset {
      margin-bottom: 0 !important;
    }
    // .ant-form-item-explain.ant-form-item-explain-connected {
    //   position: absolute;
    //   left: 0;
    //   width: calc(100vw - 40px);
    // }
  }
}

// Card

.card {
  h1,
  h2,
  h3,
  h4 {
    color: @orange;
    font-weight: @fontWeightBold;
  }
  &__viola {
    background: @viola;
    color: @white !important;
    p {
      font-weight: @fontWeightBold;
    }
  }
  small {
    text-transform: uppercase;
  }
  .icon--mini {
    position: absolute;
    top: -22px;
    right: 10px;
    width: 22px;
    height: 22px;
  }
}

// Link
.link {
  &.small {
    font-size: @font-size-small;
  }
}

// Form
.ant-form-item-explain {
  padding-bottom: 20px;
  font-size: @font-size-small;
}

// DatePicker
.ant-picker {
  width: 100%;

  .ant-picker-input {
    width: 100%;
    .ant-picker-suffix {
      color: @orange;
    }
  }
}

// Notification
.ant-notification {
  top: 0 !important;
  margin-right: 0 !important;
  .ant-notification-notice {
    max-width: none;
    width: 100%;
    border-radius: 0;
    .ant-notification-notice-message {
      color: @white;
    }
    .ant-notification-notice-description {
      color: @white;
    }
    .ant-notification-notice-close {
      color: @orange;
    }
  }
}
