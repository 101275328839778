@import (css) url("//fonts.googleapis.com/css?family=Poppins:wght@400;700,900&display=swap");
//@font-family: -apple-system, Lato, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
//  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Defaul Color
@black: #000000;
@white: #ffffff;
@grey: #656565;

// Brand Color
@orange: #ff4a1a;
@orangeDark: darken(#ff4a1a, 10);
@viola: #47082c;
@violaMedium: #ededff;
@violaLight: #f6f6ff;

@font-family: "Poppins", sans-serif;
@font-size-large: 18px;
@font-size-base: 16px;
@font-size-small: 14px;
@font-size-micro: 12px;
@font-size-nano: 10px;
@fontWeightRegular: 400;
@fontWeightBold: 900;
@line-height-base: 24px;

@text-color: @viola;
@heading-color: @viola;

@typography-title-font-weight: 400;
@heading-1-size: 32px;
@heading-2-size: 24px;
@heading-5-size: @font-size-base;

// Link
@link-color: @orange;
@link-hover-color: color(~`colorPalette("@{link-color}", 5) `);
@link-active-color: color(~`colorPalette("@{link-color}", 7) `);

// Button
@btn-border-radius-base: 40px;
@btn-border-radius-sm: @btn-border-radius-base;
@btn-default-color: @white;
@btn-default-bg: @orange;
@btn-default-border: @orange;
@btn-padding-horizontal-base: 32px;
@btn-padding-horizontal-lg: @btn-padding-horizontal-base;
@btn-padding-horizontal-sm: @btn-padding-horizontal-base;

// Layout
@layout-body-background: @white;
@layout-footer-background: @violaLight;

@border-radius-base: 10px;
@border-radius-sm: 10px;

// Form
@switch-color: @orange;
@input-height-base: 40px;

// Card
@card-background: @violaMedium;

// Notification
@notification-bg: @viola;
@notification-padding-vertical: 16px;
@notification-padding-horizontal: 24px;
